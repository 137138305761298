import { ISkillModel } from './Qualification.types';

export const SORT_OPTIONS = {
  Alpha: 'name',
  Category: 'category',
  Recent: 'lastUsed',
  Experience: 'yearsExp',
};

export const skillData: ISkillModel[] = [
  {
    name: 'Javascript',
    symbol: 'js',
    version: '2019',
    yearsExp: 15,
    lastUsed: 2023,
    mood: '',
    category: 'languages',
  },
  {
    name: 'Typescript',
    symbol: 'ts',
    version: '4',
    yearsExp: 15,
    lastUsed: 2023,
    mood: '',
    category: 'languages',
  },
  {
    name: 'Angular',
    symbol: 'ng',
    version: '11',
    yearsExp: 8,
    lastUsed: 2021,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'Adobe CC',
    symbol: 'cc',
    version: null,
    yearsExp: 20,
    lastUsed: 2021,
    mood: '',
    category: 'software',
  },
  {
    name: 'HTML',
    symbol: 'htm',
    version: null,
    yearsExp: 20,
    lastUsed: 2023,
    mood: '',
    category: 'languages',
  },
  {
    name: 'CSS',
    symbol: 'css',
    version: '3',
    yearsExp: 20,
    lastUsed: 2023,
    mood: '',
    category: 'languages',
  },
  {
    name: 'React',
    symbol: 'jsx',
    version: '17',
    yearsExp: 1,
    lastUsed: 2023,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'Sass',
    symbol: 'ss',
    version: null,
    yearsExp: 10,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Npm',
    symbol: 'np',
    version: null,
    yearsExp: 8,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Webpack',
    symbol: 'wp',
    version: '5',
    yearsExp: 5,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Less',
    symbol: 'ls',
    version: null,
    yearsExp: 4,
    lastUsed: 2016,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Json',
    symbol: 'jsn',
    version: null,
    yearsExp: 14,
    lastUsed: 2023,
    mood: '',
    category: 'languages',
  },
  {
    name: 'Rest',
    symbol: 'rst',
    version: null,
    yearsExp: 13,
    lastUsed: 2023,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'Lint',
    symbol: 'lnt',
    version: null,
    yearsExp: 4,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Prettier',
    symbol: 'pt',
    version: null,
    yearsExp: 4,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Babel',
    symbol: 'bb',
    version: null,
    yearsExp: 5,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'Jasmine',
    symbol: 'jsm',
    version: null,
    yearsExp: 6,
    lastUsed: 2021,
    mood: '',
    category: 'testing',
  },
  {
    name: 'Karma',
    symbol: 'kr',
    version: null,
    yearsExp: 6,
    lastUsed: 2021,
    mood: '',
    category: 'testing',
  },
  {
    name: 'Jest',
    symbol: 'jt',
    version: null,
    yearsExp: 6,
    lastUsed: 2023,
    mood: '',
    category: 'testing',
  },
  {
    name: 'Selenium',
    symbol: 'sl',
    version: null,
    yearsExp: 2,
    lastUsed: 2014,
    mood: '',
    category: 'testing',
  },
  {
    name: 'Bootstrap',
    symbol: 'bs',
    version: '5',
    yearsExp: 10,
    lastUsed: 2023,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'Git',
    symbol: 'gt',
    version: null,
    yearsExp: 10,
    lastUsed: 2023,
    mood: '',
    category: 'software',
  },
  {
    name: 'Jwt',
    symbol: 'jwt',
    version: null,
    yearsExp: 7,
    lastUsed: 2023,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'SSO',
    symbol: 'sso',
    version: null,
    yearsExp: 10,
    lastUsed: 2023,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'Agile',
    symbol: 'al',
    version: null,
    yearsExp: 10,
    lastUsed: 2023,
    mood: '',
    category: 'process',
  },
  {
    name: 'PrimeNg',
    symbol: 'pn',
    version: '7',
    yearsExp: 2,
    lastUsed: 2021,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'PrimeReact',
    symbol: 'pr',
    version: '8',
    yearsExp: 1,
    lastUsed: 2023,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'Jira',
    symbol: 'jr',
    version: null,
    yearsExp: 10,
    lastUsed: 2023,
    mood: '',
    category: 'software',
  },
  {
    name: 'Jenkins',
    symbol: 'jk',
    version: null,
    yearsExp: 4,
    lastUsed: 2021,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'BitBucket',
    symbol: 'bk',
    version: null,
    yearsExp: 7,
    lastUsed: 2023,
    mood: '',
    category: 'software',
  },
  {
    name: 'GitLab',
    symbol: 'gl',
    version: null,
    yearsExp: 1,
    lastUsed: 2023,
    mood: '',
    category: 'software',
  },
  {
    name: 'Accessibility',
    symbol: 'ay',
    version: null,
    yearsExp: 1,
    lastUsed: 2019,
    mood: '',
    category: 'languages',
  },
  {
    name: 'i18n',
    symbol: 'in',
    version: null,
    yearsExp: 1,
    lastUsed: 2021,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'CI/CD',
    symbol: 'ci',
    version: null,
    yearsExp: 3,
    lastUsed: 2023,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'Microservices',
    symbol: 'mc',
    version: null,
    yearsExp: 6,
    lastUsed: 2023,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'Docker',
    symbol: 'dc',
    version: null,
    yearsExp: 3,
    lastUsed: 2023,
    mood: '',
    category: 'architecture',
  },
  {
    name: 'Ag-grid',
    symbol: 'ag',
    version: null,
    yearsExp: 3,
    lastUsed: 2023,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'HighCharts',
    symbol: 'hc',
    version: null,
    yearsExp: 1,
    lastUsed: 2014,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'amCharts',
    symbol: 'ac',
    version: null,
    yearsExp: 1,
    lastUsed: 2023,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'gulp',
    symbol: 'gp',
    version: null,
    yearsExp: 5,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'storybook',
    symbol: 'sb',
    version: null,
    yearsExp: 1,
    lastUsed: 2023,
    mood: '',
    category: 'tools',
  },
  {
    name: 'design systems',
    symbol: 'ds',
    version: null,
    yearsExp: 4,
    lastUsed: 2023,
    mood: '',
    category: 'design',
  },
  {
    name: 'Php',
    symbol: 'php',
    version: null,
    yearsExp: 7,
    lastUsed: 2010,
    mood: '',
    category: 'languages',
  },
  {
    name: 'MySql',
    symbol: 'msq',
    version: null,
    yearsExp: 4,
    lastUsed: 2010,
    mood: '',
    category: 'languages',
  },
  {
    name: 'Mustache',
    symbol: 'me',
    version: null,
    yearsExp: 2,
    lastUsed: 2017,
    mood: '',
    category: 'languages',
  },
  {
    name: 'Vue',
    symbol: 'vue',
    version: null,
    yearsExp: 1,
    lastUsed: 2020,
    mood: '',
    category: 'frameworks',
  },
  {
    name: 'Oop',
    symbol: 'oop',
    version: null,
    yearsExp: 6,
    lastUsed: 2021,
    mood: '',
    category: 'languages',
  },
  {
    name: 'Yaml',
    symbol: 'yml',
    version: null,
    yearsExp: 2,
    lastUsed: 2023,
    mood: '',
    category: 'languages',
  },
];
