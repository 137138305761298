import './Banner.scss';

const Banner = () => {
  return (
    <section className='row section-banner'>
      <div className='col-8 p-4 mb-2 d-grid'>
        <h2 className=''>Software Engineer</h2>
        <p>
          UI Specialist <span className='font-size-xxl color-gray-400'>*</span> Frontend Lead
        </p>
      </div>
      <div className='col-4 collage'></div>
    </section>
  );
};

export default Banner;
